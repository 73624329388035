import React from 'react'
import Navbar from './Navbar'
import '../App.css'
import Footer3 from './Footer3'

const OnlineAdmissions = () => {
  return (
    <div >
         <Navbar/>
         <div className='App-header bg-blue-500'>
         <h2>No Admissions are accepted now!</h2>
         </div>
         <Footer3/>
    
    </div>
  )
}

export default OnlineAdmissions
