import children1 from '../Assests/Children-1.jpeg'
import children2 from '../Assests/Children-2.jpeg'
import children3 from '../Assests/Children-3.jpeg'
import children4 from '../Assests/Childrensday-wallpaper.jpeg'
import Christmas1 from '../Assests/Children-1.jpeg'
import Christmas2 from '../Assests/Christmas-2.jpeg'
import Christmas3 from '../Assests/Christmas-3.jpeg'
import Christmas4 from '../Assests/Christmas-4.jpeg'
import Christmas5 from '../Assests/Christmas-5.jpeg'
import Christmas6 from '../Assests/Christmas-6.jpeg'
import investiture1 from '../Assests/investiture-1.jpeg'
import investiture2 from '../Assests/investiture-2.jpeg'
import investiture3 from '../Assests/investiture-3.jpeg'
import investiture4 from '../Assests/investiture-4.jpeg'
import investiture5 from '../Assests/investiture-5.jpeg'
import investiture6 from '../Assests/investiture-6.jpeg'


export const data =[

      {
        name : "ChildrensDay",
        images:[
            {
           img: children1
            },
            {
             img: children2
            },
            {
            img: children3
            },
            {
            img: children4
            }    
        ]
      },
      {
        name : "ChristmasDay",
        images:[
            {
           img: Christmas1
            },
            {
             img: Christmas2
            },
            {
            img: Christmas3
            },
            {
            img: Christmas4
            },  
            {
            img: Christmas5
            },
            {
            img: Christmas6
            },   
        ]

      },
      {
        name : "InvestitureCeremony",
        images:[
            {
           img: investiture1
            },
            {
             img: investiture2
            },
            {
            img: investiture3
            },
            {
            img: investiture4
            },  
            {
            img: investiture5
            },
            {
            img: investiture6
            },    
        ]

      }


]