import React from 'react';
import Navbar from './Navbar';
import Footer3 from './Footer3';

const feesData = [
  { class: 'I', totalFee: '56,000', term1: '14,000', term2: '14,000', term3: '14,000', term4: '14,000' },
  { class: 'II', totalFee: '56,000', term1: '14,000', term2: '14,000', term3: '14,000', term4: '14,000' },
  { class: 'III', totalFee: '56,000', term1: '14,000', term2: '14,000', term3: '14,000', term4: '14,000' },
  { class: 'IV', totalFee: '56,000', term1: '14,000', term2: '14,000', term3: '14,000', term4: '14,000' },
  { class: 'V', totalFee: '57,200', term1: '14,300', term2: '14,300', term3: '14,300', term4: '14,300' },
  { class: 'VI', totalFee: '57,200', term1: '14,300', term2: '14,300', term3: '14,300', term4: '14,300' },
  { class: 'VII', totalFee: '57,200', term1: '14,300', term2: '14,300', term3: '14,300', term4: '14,300' },
  { class: 'VIII', totalFee: '60,000', term1: '15,000', term2: '15,000', term3: '15,000', term4: '15,000' },
  { class: 'IX', totalFee: '60,000', term1: '15,000', term2: '15,000', term3: '15,000', term4: '15,000' },
  { class: 'X', totalFee: '60,000', term1: '15,000', term2: '15,000', term3: '15,000', term4: '15,000' },
];

const FeeTable = () => {
  return (
    <div>
      <Navbar />
      <div className="container mx-auto  my-8 px-4 md:px-8 ">
        <h1 className="text-2xl font-bold text-center mb-4">TUITION FEE FOR ACADEMIC YEAR 2024 - 2025</h1>
        <h2 className="text-xl font-semibold text-center mb-6">CLASSES - I TO X</h2>

        {/* Enable horizontal scrolling on smaller screens */}
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse table-auto">
            <thead>
              <tr className="bg-gray-800 text-white">
                <th className="p-3 text-left">Class</th>
                <th className="p-3 text-left">Total Fee</th>
                <th className="p-3 text-left">Term-I</th>
                <th className="p-3 text-left">Term-II</th>
                <th className="p-3 text-left">Term-III</th>
                <th className="p-3 text-left">Term-IV</th>
              </tr>
            </thead>
            <tbody>
              {feesData.map((row, index) => (
                <tr
                  key={row.class}
                  className={index % 2 === 0 ? 'bg-blue-100' : 'bg-blue-200'}
                >
                  <td className="p-3">{row.class}</td>
                  <td className="p-3">{row.totalFee}</td>
                  <td className="p-3">{row.term1}</td>
                  <td className="p-3">{row.term2}</td>
                  <td className="p-3">{row.term3}</td>
                  <td className="p-3">{row.term4}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer3/>
    </div>
  );
};

export default FeeTable;
