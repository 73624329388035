import React from 'react';
import Navbar from './Navbar';

const AdmissionProcedure = () => {
  return (
 
     <div>

     <Navbar/>

    <div className="max-w-4xl mx-auto my-12 p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">Admission Procedure</h1>
      
      <section className="mb-8">
        <p className="text-lg text-gray-600 mb-4">
          <strong>Sacred Heart High School</strong> will announce the commencement of admissions by publishing dates for the issue and submission of application forms in the notice boards of all our schools. The information will also be uploaded on the school website.
        </p>
        <p className="text-lg text-gray-600 mb-4">
          Application forms will be available at the office of respective schools only.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Admission Policy & Procedure</h2>
        <p className="text-lg text-gray-600 mb-4">
          Application forms will be issued by the school office for classes where seats are available, on payment of the prescribed fee.
        </p>
        <p className="text-lg text-gray-600 mb-4">
          <strong>Timings:</strong> 10:00 a.m. to 3:00 p.m.
        </p>
        <p className="text-lg text-gray-600 mb-4">
          Completed application forms should be submitted with all the enclosures and prescribed registration fee on or before the date specified by the school. The registration fee, once paid, will not be refunded or adjusted for any other purpose. <strong>Mere registration is no guarantee for admission.</strong>
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Age for Admissions:</h2>
        <ul className="list-disc list-inside text-lg text-gray-600 space-y-2">
          <li><strong>LKG:</strong> 3 years and above</li>
          <li><strong>UKG:</strong> 4 years and above</li>
          <li><strong>Class 1:</strong> 5 years and above</li>
        </ul>
        <p className="text-lg text-gray-600 mt-4">
          The age is reckoned as on 31st March of the year of admission.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Documents to be Submitted:</h2>
        <ul className="list-disc list-inside text-lg text-gray-600 space-y-2">
          <li>Completed Application Form</li>
          <li>Passport size photos of the student and both parents should be pasted in the form.</li>
          <li>Copy of Birth Certificate from Panchayat/Municipal Corporation (bring the original for verification).</li>
          <li>If sibling case (own brothers and sisters), a Xerox copy of the sibling's school ID card must be enclosed.</li>
          <li>Guardian certificate (if applicable).</li>
          <li>Certificate of caste / Physically Challenged (SC/BC/ST/PC) (if applicable).</li>
          <li>Copy of residence proof of present status (like Telephone bill, Electricity Bill, Passport).</li>
          <li>Copy of Aadhar Card (Child, Father, Mother). Blood group report of the child.</li>
        </ul>
        <p className="text-lg text-gray-600 mt-4">
          Details of the admission procedure have been specified in the prospectus/application form.
        </p>
      </section>
    </div>
    </div>
  );
};

export default AdmissionProcedure;
