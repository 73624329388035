import React from 'react'
import Carousel from './Carousel';
import principal from '../Assests/Principal.jpeg'
import Navbar from './Navbar';
import school from '../Assests/school-1.jpg'
import ground from '../Assests/school ground.jpg'
import Footer3 from './Footer3';
import Stats from '../Components/Stats';
import EnrollmentBanner from './EnrollmentBanner';
import Correspondent from '../Assests/Correspondent.jpg';


const Home = () => {

    let slides = [
        // "https://i.pinimg.com/originals/51/82/ac/5182ac536727d576c78a9320ac62de30.jpg",
        // "https://wallpapercave.com/wp/wp3386769.jpg",
        // "https://wallpaperaccess.com/full/809523.jpg",
        // "https://getwallpapers.com/wallpaper/full/5/c/0/606489.jpg",
        school,
        ground,
        school,
        ground
      ];

  return (
    <div>
  
    

      <Navbar/>
       <div className=" h-[30%]">
         <Carousel slides={slides} />
        </div>

  {/* end of carousel  */}
 
      <div>
      <h2 className="text-4xl p-4 mt-8 text-blue-900 font-bold uppercase">
           welcome to sacred heart high school
     </h2>
        {/* <div className="flex flex-wrap items-start p-6 mx-20">
 
  <div className="w-full md:w-1/3 mb-4 md:mb-0">
    <img
      src={principal}
      alt="principal"
      className="w-full h-auto rounded-lg shadow-lg"
    />
  </div>

 
  <div className="w-full md:w-2/3 text-justify text-lg px-8 font-semibold text-gray-500">
    <p>
      As Principal of Rosary Convent High School, it is my pleasure to welcome you to our school's
      website. We thank you for taking the time to visit, and we hope to provide you with valuable
      information. Our website showcases the wealth of experiences we offer the children and gives
      them a wider audience for their wonderful skills. It serves as a platform for interaction and
      communication between the school and the parents.
    </p>
    <br />
    <p>
      As we embark on a new academic year 2024-25, we also celebrate the commencement of our
      Jubilee Year, marking 120 glorious years of Rosary Convent High School's steadfast commitment
      to "Truth and Charity." Since 1904, our school has been a beacon of light, nurturing young
      minds and fostering values that endure through generations.
    </p>
    <br />
    <p>
      This year, under the theme "Celebrating 120 years of Edu Verve - Together We REVEL," we embrace
      a journey symbolized by Rise, Embrace, Venture, Empower, and Lead. These principles
      encapsulate our mission to inspire growth, ignite passions, and cultivate leadership with
      integrity and compassion.
    </p>
    <br />
    <p>
      At Rosary Convent High School, education transcends textbooks; it is about shaping character
      and empowering each student to make a difference. Our child-centric approach emphasizes
      activity-based learning, scientific inquiry, and a spirit of curiosity. We continually update
      our teaching methods to blend classroom study, research, and discovery, preparing students to
      thrive in an ever-changing world.
    </p>
    <br />
    <p>
      Together with dedicated parents, teachers, and supporters, we empower our students to face
      challenges, enjoy learning, and embrace the future with confidence.
    </p>
    <br />
    <p>
      Let us uphold the values that define us – "Truth and Charity" – guiding our actions and
      decisions throughout this Jubilee Year. Together, let's make this year a testament to our
      enduring legacy and a stride towards a brighter future for all.
    </p>
    <br />
    <p>
      Welcome to the new academic Jubilee Year at Rosary Convent High School. Let's rise, embrace,
      venture, empower, and lead together. I anticipate your continued cooperation and support. God
      bless you all!
    </p>
    <br />
    <p>
      Yours in Education,
      <br />
      <strong>SR. JOSEPHINA KOMMAREDDY,</strong>
      <br />
      Principal
    </p>
  </div>
</div> */}

<div className="flex flex-col md:flex-row items-start md:space-x-8 p-4 md:p-8 mx-auto text-justify">
  {/* Image Section */}
  <div className="w-full md:w-1/3 mb-6 md:mb-0">
  <div className="rounded-lg  border-t-8 border-l-8 border-blue-800 p-1">

    <img
      src={principal} // Add your image source
      alt="Principal"
      className="w-full h-auto rounded-lg shadow-lg"
    />
    </div>
  </div>

  {/* Text Section */}
  <div className="w-full md:w-2/3 text-md font-poppins text-gray-600">
    <p>
    Warm welcome to Sacred Heart High School, which has completed 60 years. <br/>
    Since its inception in 1964, our school has been excelling, all round with   efficient and committed leaders and teachers. 
    </p>
    <br />
    <p>
    Our educational service aims to shape the students to  excel academically, intellectually competent, morally 
    and emotionally sound, physically strong and spiritually imbibed with a deep sense of noble values and God centered.
    </p>
    <br />
    <p>
    Our school campus has its natural ambiance and unique beauty, where one feels welcomed and attracted. Yes, you can breathe fresh air and feel healthy. 
    <br/>
   The school management, the teachers, and the supportive staff are committed to creating an atmosphere in which the zest for learning thrives and students are trained to excel. 
    <br/>
   The school aims at integral formation of the students and help them to grow as integrated persons.

    </p>
    <br />
    <p>
    The programs are students centered, and students are encouraged to build a healthy environment and gear 
    towards building a healthy society. Hence, they are trained to be harmonious with one another, nature and the divine grace/energy. They are motivated to think creatively and dream a big. 
    </p>
    <br />
    <p>
    Besides rigorous intellectual and academic programs, the school has marked its name in all spheres such as art and craft, dance singing sports events, NCC,
     psychological counselling, so on, and so forth. Our campus has an best infrastructural facility that provides to  conduct various activities during club periods 
     as well as co-curricular, inter-school and state level competitions.Our students are expected to participate actively and to take advantage of the many opportunities 
     offered, so they can develop into self-confident, good self-esteem and be responsible and compassionate human beings. 

    </p>
    <p>
      <br/>
    We wish and help our students to be transformed individuals and, in turn, to transform society. 
    Every child is a gift to our school. We believe and value their giftedness and their ability to learn and excel. 
     <br/>
   Let our school Motto truth and charity make our students stand for the truth and be always considerate and practice  charity, respect, and human dignity.
    </p>
    <br />
    <p>
      Yours in Education,
      <br />
      <strong>SR. Arogya Marry,</strong>
      <br />
      Principal
    </p>
  </div>
</div>

<h2 className="text-4xl p-4 mt-8 text-blue-900 font-bold uppercase">
           Correspondent Message
     </h2>
<div className="flex flex-col md:flex-row items-start md:space-x-8 p-4 md:p-8 mx-auto text-justify">

<div className="w-full lg:w-0 md:w-1/3 mb-6 md:mb-0 ">
<div className="rounded-lg shadow-lg border-t-8 border-r-8 border-blue-400 ">
    <img
      src={Correspondent} // Add your image source
      alt="Principal"
      className="w-full h-auto rounded-lg shadow-lg"
    />
    </div>
  </div>
  {/* Text Section */}
  <div className="w-full md:w-2/3 text-md font-poppins text-gray-600">
    <p>
    Warm welcome to Sacred Heart High School, which has completed 60 years. <br/>
    Since its inception in 1964, our school has been excelling, all round with   efficient and committed leaders and teachers. 
    </p>
    <br />
    <p>
    Our educational service aims to shape the students to  excel academically, intellectually competent, morally 
    and emotionally sound, physically strong and spiritually imbibed with a deep sense of noble values and God centered.
    </p>
    
    <br />
    <p>
    The programs are students centered, and students are encouraged to build a healthy environment and gear 
    towards building a healthy society. Hence, they are trained to be harmonious with one another, nature and the divine grace/energy. They are motivated to think creatively and dream a big. 
    </p>
    <br />
    <p>
      <br/>
    We wish and help our students to be transformed individuals and, in turn, to transform society. 
    Every child is a gift to our school. We believe and value their giftedness and their ability to learn and excel. 
     <br/>
   Let our school Motto truth and charity make our students stand for the truth and be always considerate and practice  charity, respect, and human dignity.
    </p>
    <br />
    <p>
      Yours in Education,
      <br />
      <strong>SR.Maria Goretti,</strong>
      <br />
      Correspondent
    </p>
  </div>

  <div className=" w-0 md:w-1/3 mb-6 md:mb-0 ">
  <div className="rounded-lg  border-t-8 border-r-8 border-blue-400 p-1">
    <img
      src={Correspondent} // Add your image source
      alt="Principal"
      className="w-full h-auto rounded-lg shadow-lg"
    />
    </div>
  </div>
</div>


      </div>


{/* end of letter */}

  

      <div className='p-4'>
 
      <div className="flex font-poppins flex-col md:flex-row w-3/4 mx-auto">
  {/* Our Goal */}
  <div className="flex-1 bg-yellow-600 text-white text-center p-6 ">
    <h2 className="text-2xl font-bold">OUR GOAL</h2>
    <p className="mt-4 text-lg font-medium">
      Service Through Excellence, by following our school motto "Truth and Charity".
    </p>
  </div>

  {/* Our Vision */}
  <div className="flex-1 bg-green-700 text-white text-center p-6">
    <h2 className="text-2xl font-bold">OUR VISION</h2>
    <p className="mt-4 text-lg font-medium">
      To impart all-round quality education and holistic development through active participation and competition.
    </p>
  </div>

  {/* Our Mission */}
  <div className="flex-1 bg-red-700 text-white text-center p-6">
    <h2 className="text-2xl font-bold">OUR MISSION</h2>
    <p className="mt-4 text-lg font-medium">
      To build a strong TEAM of Management, Staff, parents, and students who work in a true spirit of collaboration and co-operation.
    </p>
  </div>
</div>


      </div>
      <div>
    <Stats/>
    <EnrollmentBanner/>
   </div>
         <Footer3/>
    </div>
  )
}

export default Home
