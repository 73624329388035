import React from 'react'
import logo from '../Assests/logo.png'


const Header = () => {
  return (
    <div>
     
    <div className="flex flex-col items-center md:flex-row justify-between pt-6">
      {/* Left Logo */}
      <div className="w-1/4 md:w-1/6">
      <img
            src={logo}
            alt="Sacred Heart High School Logo"
            className="w-16 h-auto md:w-48" // Reduced the width to make the logo smaller
          />
      </div>

      {/* Main Text Section */}
      <div className="text-center md:w-2/3">
        <h1 className="text-blue-900 font-bold text-2xl md:text-5xl">
          SACRED HEART HIGH SCHOOL
        </h1>
        <h2 className="text-yellow-500 text-xl md:text-2xl mt-2">
          To Love Through Truth
        </h2>
        <p className="text-black font-bold text-lg md:text-2xl mt-2">
        Theme Of The Year 2023-24:"Building Essential Skills For Tomorrow"
        </p>
        {/* <p className="text-black font-bold text-lg md:text-xl mt-1">
          TOGETHER WE
        </p>
        <p className="text-black font-bold text-lg md:text-xl mt-1">
          <span className="text-red-600">" RISE </span>
          <span className="text-green-700">EMBRACE </span>
          <span className="text-blue-600">VENTURE </span>
          <span className="text-yellow-600">EMPOWER </span>
          <span className="text-black">LEAD "</span>
        </p> */}
      </div>

      {/* Right Mary Image */}
      <div className="w-0 md:w-1/6">
      <img
            src={logo}
            alt="Rosary Convent School Logo"
            className="w-16 h-auto md:w-48" // Reduced the width to make the logo smaller
          />
      </div>
    </div>


    </div>
  )
}

export default Header
