import React from 'react';
import children1 from '../Assests/Children-1.jpeg'
import children2 from '../Assests/Children-2.jpeg'
import children3 from '../Assests/Children-3.jpeg'
import children4 from '../Assests/Childrensday-wallpaper.jpeg'
import Christmas1 from '../Assests/Children-1.jpeg'
import karate from '../Assests/karate.jpeg'
import Navbar from './Navbar';

const activities = [
  {
    title: 'Karate',
    image: karate, // Replace with the actual path to the image
    description: 'Karate classes are held twice a week, focusing on self-defense, discipline, and fitness.',
  },
  {
    title: 'Scouts and Guides',
    image: children2, // Replace with the actual path to the image
    description: 'The Scouts and Guides program promotes leadership and outdoor skills among students.',
  },
  {
    title: 'School Band',
    image:  children3, // Replace with the actual path to the image
    description: 'Join the school band to learn music and perform at various school events and competitions.',
  },
  {
    title: 'Dance',
    image: children4,// Replace with the actual path to the image
    description: 'Dance classes include various styles such as hip-hop, ballet, and contemporary dance.',
  },
  {
    title: 'Yoga',
    image: children4, // Replace with the actual path to the image
    description: 'Yoga classes focus on mindfulness, relaxation, and physical health, suitable for all students.',
  },
  {
    title: 'Eco Club',
    image: children4, // Replace with the actual path to the image
    description: 'The Eco Club engages students in environmental conservation and sustainability projects.',
  },
];

const Activities = () => {
  return (
    <div>
    <Navbar/>
    <div className="activities-container">
      {activities.map((activity, index) => (
        <div key={activity.title} className={`activity-item ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'} flex items-center my-8`}>
          <div className="activity-image w-full md:w-1/2">
            <img src={activity.image} alt={activity.title} className="object-cover rounded-lg" />
          </div>
          <div className="activity-content w-full md:w-1/2 p-4">
            <h2 className="text-2xl font-bold mb-2">{activity.title}</h2>
            <p className="text-gray-700">{activity.description}</p>
          </div>
        </div>
      ))}
    </div>
        
    </div>
  );
};

export default Activities;
