import React from 'react'

const Footer2 = () => {

    const Services = [
        " Personal Loan",
        "Personal Loan for salaried",
       "Purchase On EMI",
        "Salary on Demand",
    ]
    
    const company =[
        "About Us",
      "Academics",
      "Events",
    "Achievements",
    
    ]
    const legal =[
        "Terms and Conditions",
    "Privacy Policy",
    "Grievance Redressal",
    
    ]

  return (
    <div>
       <div className='bg-black text-white '>
      <footer class=" body-font ">
    <div className='flex flex-row px-4' >
  <div class="container px-5 py-16 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
    
   
    <div class="flex-grow flex flex-wrap md:pl-20    py-4 justify-between -mb-10 md:mt-0 mt-10 md:text-left text-center">
    <div class="lg:w-1/4 md:w-1/2 w-full px-4 mb-6 text-center  ">
        <h2 class="title-font  md:text-start sm:text-center text-amber-400 text-base mb-3 font-semibold underline underline-offset-[5px]">Quick Links</h2>
        
        {
            company.map(s =>
              <ul>
                <nav class="list-none mb-2 md:text-start sm:text-center font-poppins"> 
                <li className='py-1 cursor-pointer'>
                  <a class="text-white   hover:underline underline-offset-2">{s}</a>
                </li>
              </nav>
              </ul>
                )
        } 
      </div>
      <div class="lg:w-1/4 md:w-1/2 w-full px-4 mb-6 ">
        <h2 class="title-font md:text-start sm:text-center text-amber-400 text-base  mb-3  font-semibold underline underline-offset-[5px]">Quick Links</h2>
        
        {
            company.map(s =>
                <nav class="list-none mb-2 md:text-start sm:text-center">
                  <ul>
                <li className='py-1 cursor-pointer'>
                  <a class="text-white  font-poppins  hover:underline underline-offset-2">{s}</a>
                </li>
                </ul>
              </nav>
                )
        } 
      </div>
      <div class="lg:w-1/4 md:w-1/2 w-full px-4 mb-6 ">
        <h2 class="title-font md:text-start sm:text-center text-amber-400 text-base  mb-3  font-semibold underline underline-offset-[5px]">Quick Links</h2>
        
        {
            company.map(s =>
                <nav class="list-none mb-2 md:text-start sm:text-center">
                  <ul>
                <li className='py-1 cursor-pointer'>
                  <a class="text-white  font-poppins  hover:underline underline-offset-2">{s}</a>
                </li>
                </ul>
              </nav>
                )
        } 
      </div>
       
      
    </div>
    {/* <div className="lg:w-1/2 md:w-1/2 w-full px-4 text-xl text-center py-10 font-poppins m-auto  ">
        
       <h2 className='text-lg font-bold text-amber-400 '>Reach US</h2> 
       <p><span className='font-bold text-amber-200'>Address:</span> Sacred heart high school, </p>
       <p> Opposite 1town police station,</p>
       <p>Godavarikhani, Peddapally (dist).</p>
       <p>Phone : 08728-244852, 244414</p>
      </div> */}
       <div className="w-full px-4 sm:px-6 md:w-2/3 lg:w-1/2 text-center py-6 font-poppins m-auto">
          <h2 className="text-lg font-bold text-amber-400">Reach US</h2>
          <p>
            <span className="font-bold text-amber-200">Address:</span> Sacred
            Heart High School,
          </p>
          <p>Opposite 1 Town Police Station,</p>
          <p>Godavarikhani, Peddapally (dist).</p>
          <p>Phone: 08728-244852, 244414</p>
        </div>
  

  </div>
  </div>
  <div className='mb-16  md:items-center  sm:flex-col ml-16 md:ml-32 '>
    
      <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 px-4 justify-between ">
        <a href='https://www.facebook.com/people/Credit-Mitra/61554428303586/?mibextid=LQQJ4d' target="_blank" class="text-sky-500 md:px-8">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </a>
        <a href='https://twitter.com/CreditMitra' target='_blank' class="ml-3 text-sky-500 md:px-8">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
         
        </a>
        <a href='https://www.instagram.com/creditmitra_official?igsh=YzE2c3d1MjVlcWpv'  target="_blank" class="ml-3 text-pink-500 md:px-8">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
          </svg>
        </a>
        
      </span>

      

    
      </div>
  
  <div class="bg-gray-300">
    <div class="container mx-auto py-4 px-5 flex flex-wrap justify-center flex-col sm:flex-row">
      <p class="text-gray-500 text-sm text-center sm:text-left">© 2024 Sacred Heart High School —
        <a href="https://twitter.com/knyttneve" rel="noopener noreferrer" class="text-gray-600 ml-1" target="_blank">All Rights are Reserved</a>
      </p>
    
    </div>
  </div>

</footer>
</div>
    </div>
  )
}

export default Footer2
