import React, { useState } from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const statsData = [
  { id: 1, value: 4950, label: "Students", icon: "🎓" },
  { id: 2, value: 356, label: "Class Rooms", icon: "📚" },
  { id: 3, value: 50, label: "School Buses", icon: "🚌" },
  { id: 4, value: 429, label: "Total Teachers", icon: "👩‍🏫" },
];

const Stats = () => {
  // Use Intersection Observer API to detect when the component comes into view
  const [hasAnimated, setHasAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation should trigger only once
    threshold: 0.2, // Trigger when 20% of the element is in view
  });

  if (inView && !hasAnimated) {
    setHasAnimated(true); // Trigger the animation once
  }

  return (
    <div ref={ref} className="bg-blue-500 py-16">
      <div className="container mx-auto flex flex-wrap justify-center items-center gap-8 px-4">
        {statsData.map((stat) => (
          <div
            key={stat.id}
            className="flex flex-col items-center bg-white rounded-full h-36 w-36 sm:h-44 sm:w-44 md:h-52 md:w-52 lg:h-56 lg:w-56 border-dashed border-2 border-white justify-center text-center shadow-lg"
          >
            <div className="text-4xl md:text-5xl mb-2">{stat.icon}</div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-700">
              {hasAnimated ? (
                <CountUp start={0} end={stat.value} duration={2.5} />
              ) : (
                "0"
              )}
              +
            </h2>
            <p className="text-lg md:text-xl text-gray-600">{stat.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stats;
