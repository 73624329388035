import React, { useState } from "react";
import loginimage from '../Assests/loginimage.jpg'

const LoginPage = () => {
  const [role, setRole] = useState(""); // State to store dropdown value
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can now store and handle the form data as per your needs
    console.log("Selected Role:", role);
    console.log("Registration Number:", registrationNumber);
    console.log("Password:", password);
    // Do your form submission here (e.g., API calls)
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      {/* Left Side Image */}
      <div className="hidden lg:block lg:w-1/2">
        <img
          src={loginimage} // Replace with your image path
          alt="Left Side"
          className="object-cover w-full h-full"
        />
      </div>

      {/* Right Side Login Form */}
      <div className="flex flex-col justify-center items-center lg:w-1/2 w-full bg-gray-50 px-8 py-12">
        <h2 className="text-4xl font-bold text-gray-800 mb-6 animate__animated animate__backInDown"  >Welcome Back!</h2>
        <p className="text-gray-500 mb-6">
          Login to your account using your registration number and password.
        </p>

        {/* Login Form */}
        <form className="w-full max-w-md" onSubmit={handleSubmit}>
          {/* Dropdown Input for Role */}
          <div className="mb-4">
            <label htmlFor="role" className="block text-gray-700 text-sm font-bold mb-2">
              Select Role
            </label>
            <select
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              required
            >
              <option value="" disabled>Select your role</option>
              <option value="student">Student</option>
              <option value="admin">Admin</option>
              <option value="teacher">Teacher</option>
            </select>
          </div>

          {/* Registration Number Input */}
          <div className="mb-4">
            <label htmlFor="regNumber" className="block text-gray-700 text-sm font-bold mb-2">
              Registration Number
            </label>
            <input
              type="text"
              id="regNumber"
              placeholder="Enter your registration number"
              value={registrationNumber}
              onChange={(e) => setRegistrationNumber(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              required
            />
          </div>

          {/* Password Input */}
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
              Password
            </label>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              required
            />
          </div>


          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            Log In
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
