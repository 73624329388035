import React from "react";

const EnrollmentBanner = () => {
  return (
    <div className="bg-blue-500 py-10 flex flex-col md:flex-row items-center justify-between px-8 md:px-16 lg:px-24">
      {/* Left Side Text */}
      <div className="text-white max-w-3xl space-y-2">
        <h1 className="text-3xl lg:text-4xl font-bold">
          ENROLL YOUR CHILD FOR 2023-2024
        </h1>
        <p className="text-lg lg:text-xl">
         School's motto is to turn learning into a joyful effort. <br />
          It’s our honour to continue this journey for more than two decades by
          inspiring young minds.
        </p>
      </div>

      {/* Right Side Button */}
      <div className="mt-6 md:mt-0">
        <button
          className="bg-white text-blue-500 font-bold py-2 px-6 rounded-lg hover:bg-gray-100 transition duration-300"
          aria-label="Enroll Now"
        >
        <a href="/admissions">Enroll Now!</a>
        </button>
      </div>
    </div>
  );
};

export default EnrollmentBanner;
