import React from 'react'
import Navbar from './Navbar'
import calendar from '../Assests/calendar.jpg'

const Calendar = () => {
  return (
    <div>
        <Navbar/>
        <div className="container mx-auto my-8 px-4 md:px-0">

            <img src={calendar} alt='SCHOOL CALENDAR' />

        </div>
      
    </div>
  )
}

export default Calendar
