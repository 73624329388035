import React from 'react';
import Navbar from './Navbar';
import Footer2 from './Footer2';
import Activities from './Activities';

const Contactus = () => {
  return (
    <div>
      <Navbar />

      <section className="text-gray-600 body-font  relative flex flex-col items-center mx-auto">
        {/* Responsive Google Maps iFrame */}
        <div className="w-full px-4 sm:px-6 md:w-2/3 lg:w-1/2 py-4">
          <iframe
            width="100%" // Make the iframe full width on all devices
            height="400"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Sacreed%20Heart%20High%20Scool%20,GDK%20Bus%20Station%20Rd,%20near%20Police%20Station,%20Ram%20Nagar,%20Godavarikhani,%20Ramagundam,%20Telangana%20505209+(Sacred%20Heart%20High%20School)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>

        {/* Responsive Address Section */}
        <div className="w-full px-4 sm:px-6 md:w-2/3 font-poppins lg:w-1/2 text-center py-10  m-auto">
          <h2 className="text-lg font-bold text-amber-400">Reach US</h2>
          <p>
            <span className="font-bold text-amber-200">Address:</span> Sacred
            Heart High School,
          </p>
          <p>Opposite 1 Town Police Station,</p>
          <p>Godavarikhani, Peddapally (dist).</p>
          <p>Phone: 08728-244852, 244414</p>
        </div>
      </section>

      {/* Footer */}
      <div className="pt-2">
        <Footer2 />
      </div>
    </div>
  );
};

export default Contactus;
